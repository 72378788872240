export const ShopContent = [
    {
        title: "Unikatni spominki iz našega rudnika",
        description: ": V recepciji rudnika lahko ogledate in kupite unikatne spominke prinesene iz rudniških odkopnih polj. Gre predvsem za galenitne, limonitne, hematitne in baritne unikatne spominke, ki so lično zapakirani in odlični za simbolično darilo.",
        images_directory: "spominki",
    },
    {
        title: "Obesek s Hematitom",
        description: "Hematit je kristal, ki krepi našo vez z zemljo in nam daje občutek varnosti in domačnosti. Deluje tako, da uravnoteži energijo telesa, uma in duha. Hematit čisti in ščiti auro, prizemljuje, odganja negativne energije odpravlja strahove, zmanjšuje vsakodnevno napetost in izboljša krvni pretok. Pri zdravljenju pa vpija bolečino iz telesa na mestu, kamor ga položimo.",
        images_directory: "hematitni_nakit"
    },
    {
        title: "Knjiga V temnih globinah Sitarjevca",
        description: "Knjigo v kateri se nahaja 6 kratkih zgodbic je napisala Darinka Kobal. Po opisu pripovedovalcev zgodb je knjigo ilustrirala Anita Vozelj.",
        images_directory: "knjiga_v_temnih_globinah_sitarjevca"
    },
    {
        title: "Knjiga Stara sodnija",
        description: "Knjigo v kateri se nahajajo 4 zgodbe  je napisala Darinka Kobal. Za izvrstne ilustracije pa je poskrbela Anita Vozelj",
        images_directory: "knjiga_stara_sodnija"
    },
    {
        title: "Knjiga Ko Litija še ni bila Litija",
        description: "V knjigi pisateljica Darinka Kobal opisuje kje so se staroselci nekoč naselili, kako in s čim so se preživljali in v kakšnih domovih so živeli. Anita Vozelj je pisateljičino besedilo podprla s lepimi ilustracijami.",
        images_directory: "knjiga_ko_litija_se_ni_bila_litija"
    },
    {
        title: "Nahrbtnik Litko",
        description:"Litko je rudar in maskota litijskega karnevala, ki razveseljuje obiskovalce na vseh večjih dogodkih v Litiji.  Domuje v rudniku Sitarjevec in se skriva v njegovih globinah. Njegova podoba krasi tudi priročen nahrbtnik iz neprepustnega materiala.",
        images_directory: "nahrbtnik_litko"
    },
    {
        title: "Svinčnik Rudnik Sitarjevec",
        description: "Uporaben spominek - črn svinčnik z imenom in logom rudnika.",
        images_directory: "svincnik_rudnik_sitarjevec"
    },
    {
        title: "Črne nogavice Rudnik Sitarjevec",
        description: "Uporaben spominek -  udobne črne nogavice z imenom in logom rudnika.",
        additional_text: "Material: 90% česan bombaž in 10% elastan.",
        images_directory: "nogavice"
    },
    {
        title: "Razglednice z rudniškim motivom",
        description: "Uporaben spominek – 2 vrsti razglednic z imenom in logom rudnika. Vključena je tudi znamka.",
        images_directory: "razglednice"
    },
    {
        title: "Magneti",
        description: "Za nakup je na voljo več različnih magnetov z motivi povezanimi z rudnikom.",
        images_directory: "magneti"
    },
    {
        title: "Svinčeni ulitki",
        description: "Recepcija rudnika ponuja pester nabor izdelkov iz svinčenih ulitkov.",
        additional_text: "V dneh okrog novega leta je bilo v Sloveniji od sredine 20. stoletja v navadi vedeževanje z vlivanjem staljenega svinca (ali voska) v mrzlo vodo. Ta tradicija je del bogatega slovenskega izročila, šega in navad, ki izvirajo iz poganskih verskih in posvetnih obredov. Po navadi so vlili tri ulitke, za preteklost, sedanjost in prihodnost iz katerih so potem prerokovali.",
        images_directory: "svinceni_ulitki"
    },
    {
        title: "Keramični izdelki z motivi rudnika",
        description: "Za nakup je na voljo širok nabor različnih keramičnih skodelic in šalic z motivi rudnika Sitarjevec.",
        images_directory: "keramicni_izdelki"
    },
    {
        title: "Drobižnica in puščica v odtenkih rudniškega pigmenta okre",
        description: "Drobižnica in puščica v odtenkih rudniškega pigmenta okre in limonita je odlično darilo in spomin na obisk rudnika.",
        images_directory: "drobiznica_in_puscica"
    },
    {
        title: "Barvice",
        description: "Prikupen komplet 6 barvic s šilčkom in logotipom rudnika.",
        images_directory: "barvice"
    },
    {
        title: "Igrača netopir iz tekstila",
        description: "Za naše najmlajše lahko v recepciji kupite prikupno igračo v obliki netopirja. Primerna je tudi za podporo glave na daljših potovanjih.\n",
        images_directory: "igraca_netopir"
    },
    {
        title: "Obesek za ključe netopir",
        description: "Izvirno darilo iz našega rudnika so obeski za ključe v obliki netopirja.",
        images_directory: "obesek_netopir"
    },
    {
        title: "Knjiga Fosili Slovenije",
        description: "Knjiga Fosili Slovenije avtorjev dr. Bogdana Jurkovška in dr. Tee Kolar-Jurkovšek predstavlja sprehod skozi geološka obdobja s pomočjo makrofosilov najdenih v Sloveniji, ob prepoznavanju načina življenja nekdanjih organizmov, njihovih združb in okolja v katerem so živeli ter razmer ob njihovi smrti in kasnejši fosilizaciji. Predstavljeni so nekateri ključni geološki dogodki, od lokalnih in regionalnih do globalnih, ki so krmarili evolucijske poti živega sveta vse do danes.",
        images_directory: "knjiga_fosili_slovenije"
    },
    {
        title: "Emajlirane skodelice rudnika Sitarjevec",
        description: "Na voljo za nakup so lične emajlirane skodelice z različnimi motivi, ki spominjajo na Rudnik Sitarjevec Litija.",
        images_directory: "loncki"
    },
    {
        title: "Črna/bela majica rudnika",
        description: "Črna ali bela majica z zemljevidom rovov in logom rudnika za vse starostne skupine.",
        additional_text: "Material: 100% bombaž",
        images_directory: "majice"
    },
    {
        title: "Kemični svinčnik Rudnik Sitarjevec",
        description: "Uporaben spominek - črn svinčnik z imenom in logom rudnika.",
        images_directory: "kemicni_svincnik"
    },
    {
        title: "Unikatna voščilnica",
        description: "Ročno izdelana voščilnica iz naravnih materialov je idealno darilo za posebne priložnosti.\n",
        images_directory: "unikatna_voscilnica"
    },
    {
        title: "Štajger – zeliščni čaj rudarjev",
        description: "Štajgerjev zeliščni čaj vsebuje mešanico zelišč, ki so ga nekoč pili rudarji. Izbor zelišč ugodno vpliva na dihala in pljuča.",
        images_directory: "stajgerjev_caj"
    },
    {
        title: "Trak za ušesa",
        description: "Trak za ušesa s rudarskim motivom.",
        images_directory: "trak"
    },
    {
        title: "Zeliščni darilni paket",
        description: "Različne darilne pakete pripravljajo na Kmetiji Pr' Janez. Vsebujejo čaj za dobro počutje, zelenjavni dodatek k jedem ali kis z zelenjavo.",
        images_directory: "darilni_paket"
    },
    {
        title: "Med",
        description: "Za nakup so na voljo različne vrste medu Čebelarstva Dremelj.",
        images_directory: "med"
    },
    {
        title: "Eko suho sadje",
        description: "Eko suho sadje – sadne ploščice, ki so zdrav nadomestek za sladice.",
        images_directory: "suho_sadje"
    },
    {
        title: "Rudarski škratek Sitarjevček ",
        description: "Škrat je izdelan iz česane ovčje volne v tehniki suhega polstenja (s posebno iglo). Zanj je značilna rdeča kapa, brada in brki so črno-sivi-beli (umazani od prahu in blata)",
        images_directory: "rudarski_skratek"
    },
    {
        title: "Skriti kamen",
        description: "Pri ovijanju kamna v polst je uporabljena volna iz Eko kmetije Vovše, kamni pa so pridobljeni iz rudnika Sitarjevec Litija. ",
        images_directory: "skriti_kamen"
    }
];