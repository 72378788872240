import React from "react";
import Header from "../../components/headers/Header";
import CallToAction from "../../components/layouts/CallToAction";
import ShopHeader from "../../components/shop/ShopHeader";
import Footer from "../../components/layouts/Footer";
import ShopDescription from "../../components/shop/ShopDescription";
import SEO from "../../components/seo/Seo";
import { graphql } from "gatsby";

function TrgovinaInSpominki(props) {
    return (
        <div>
            <SEO
                title="Trgovina in spominki"
                description="V recepciji našega rudnika lahko kupite različne spominke, magnet Sitarjevec ali magnet litijski srebrnik. Prodajamo tudi nakit iz hematita, spominke iz različnih rud in mineralov, med drugim spominek hematit-barit, limonit, galenit iz rudnika Sitarjevec Litija. Na voljo za nakup so tudi 3 knjige: V temnih globinah Sitarjevca, Stara sodnija, Ko Litija še ni bila Litija."
            />
            <Header />
            <ShopHeader />
            <ShopDescription images={props.data} />
            <CallToAction />
            <Footer />
        </div>
    );
}

export const query = graphql`
    query {
        spominki: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/spominki/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        spominek_hematit_barit: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/spominek_hematit_barit/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        spominek_limonit: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/spominek_limonit/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        spominek_galenit: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/spominek_galenit/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        hematitni_nakit: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/hematitni_nakit/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        magnet_rudnika_sitarjevec: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: {
                    regex: "/rudnik/shop/magnet_rudnika_sitarjevec/g"
                }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        magnet_litijski_srebernik: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: {
                    regex: "/rudnik/shop/magnet_litijski_srebernik/g"
                }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        knjiga_v_temnih_globinah_sitarjevca: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: {
                    regex: "/rudnik/shop/knjiga_v_temnih_globinah_sitarjevca/g"
                }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        knjiga_stara_sodnija: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/knjiga_stara_sodnija/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        knjiga_ko_litija_se_ni_bila_litija: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: {
                    regex: "/rudnik/shop/knjiga_ko_litija_se_ni_bila_litija/g"
                }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        magnet_litko: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/magnet_litko/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        nahrbtnik_litko: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/nahrbtnik_litko/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        svincnik_rudnik_sitarjevec: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: {
                    regex: "/rudnik/shop/svincnik_rudnik_sitarjevec/g"
                }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        barvice: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/barvice/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        cokoladne_dobrote: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/cokoladne_dobrote/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        drobiznica_in_puscica: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/drobiznica_in_puscica/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        igraca_netopir: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/igraca_netopir/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        kemicni_svincnik: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/kemicni_svincnik/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        keramicni_izdelki: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/keramicni_izdelki/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        knjiga_fosili_slovenije: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: {
                    regex: "/rudnik/shop/knjiga_fosili_slovenije/g"
                }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        loncek_kramp: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/loncek_kramp/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        loncek_netopir: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/loncek_netopir/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        loncki_mesano_kramp_in_netopir: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: {
                    regex: "/rudnik/shop/loncki_mesano_kramp_in_netopir/g"
                }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        loncki: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/loncki/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        loncki_rdeci_pikcasti: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/loncki_rdeci_pikcasti/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        magneti: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/magneti/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        majica_bela: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/majica_bela/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        majica_crna: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/majica_crna/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        nakit_iz_hematita: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/nakit_iz_hematita/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        nogavice: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/nogavice/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        majice: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/majice/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        obesek_netopir: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/obesek_netopir/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        razglednice: allFile(
            sort: { fields: [base] }
            filter: { relativePath: { regex: "/rudnik/shop/razglednice/g" } }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        sponinki_iz_rudnika: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/spominki_iz_rudnika/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        svinceni_ulitki: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/svinceni_ulitki/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        unikatna_voscilnica: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/unikatna_voscilnica/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        stajgerjev_caj: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/stajgerjev_caj/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        trak: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/trak/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        darilni_paket: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/darilni_paket/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        med: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/med/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        suho_sadje: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/suho_sadje/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        rudarski_skratek: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/rudarski_skratek/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }

        skriti_kamen: allFile(
            sort: { fields: [base] }
            filter: {
                relativePath: { regex: "/rudnik/shop/skriti_kamen/g" }
            }
        ) {
            nodes {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
`;

export default TrgovinaInSpominki;
